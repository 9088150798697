<template>
  <div>
    <v-container>
      <v-row class="d-flex align-center">
        <h1>
          <base-arrow-back-icon @click="$router.go(-1)">{{rightArrowIcon}}</base-arrow-back-icon>
          معلومات الدورة
        </h1>
      </v-row>
    </v-container>
    <v-card>
      <v-container>
        <v-row justify="space-between">
          <v-col sm="12" md="3">
            <div class="search-area">
              <v-text-field class="px-5" v-model="searchFromList" placeholder="بحث"></v-text-field>
              <v-list dense nav :color="this.primaryColor" style="border-radius: 20px">
                <template v-for="(item, i) in coursesList">
                  <v-list-item :key="i" link
                               :to="{ name: 'viewCourse' , params : {id: item.id} , query : {type : 'view'} }">
                    <v-list-item-content>
                      <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </div>
          </v-col>
          <v-col lg="9" sm="12" cols="12">
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <base-avatar>
                  <v-img src="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"></v-img>
                </base-avatar>
              </v-col>
              <v-col cols="12" sm="12" md="4" >
                <v-form ref="form">
                  <v-text-field
                    v-model="form.instructor"
                    label="المدرب"
                    :readonly="isView"
                  ></v-text-field>
                  <v-select
                    v-model="form.category"
                    :items="categories"
                    label="القسم"
                    :readonly="isView"
                  ></v-select>
                  <base-button
                    :options="{color : form.courseStatus.online ? this.primaryColor : this.primaryColorOverlay}"
                    @click="form.courseStatus.online = !form.courseStatus.online" class="ma-1"
                  >
                    اونلاين
                  </base-button>
                  <base-button
                    :options="{color : form.courseStatus.attendence ? this.primaryColor : this.primaryColorOverlay}"
                    @click="form.courseStatus.attendence = !form.courseStatus.attendence"
                    class="ma-1">حضوري
                  </base-button>
                  <base-button
                    :options="{color : form.courseStatus.mixed ? this.primaryColor : this.primaryColorOverlay}"
                    @click="form.courseStatus.mixed = !form.courseStatus.mixed" class="ma-1"
                  >
                    مختلط
                  </base-button>
                </v-form>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-row>
                  <v-col cols="12" sm="12" lg="4" class="d-flex justify-center align-center">
                    <v-text-field v-if="!isView"
                                  v-model="form.learningPath"
                                  label="المسار التدريبي"
                                  class="mt-5"
                                  @click="addCourseToLearningPath()"
                    ></v-text-field>
                    <base-button v-if="!isView" options="" @click="addCourseToLearningPath()" class="mt-4 mx-0">+
                    </base-button>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn v-for="(item , index) in form.learningPathValues"
                           :key="index" class="ma-2">{{item}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" lg="5">
                    <v-text-field
                      :readonly="isView"
                      v-model="form.price"
                      label="السعر"
                      class="mt-15"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" lg="6" class="d-flex justify-center align-end">
                    <v-row class="d-flex justify-end align-end mb-4">
                      <v-col cols="12" lg="6" sm="12" >
                        <base-button v-if="!isView" :options="{block : true}">حفظ</base-button>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import BaseButton from "../../../core/Base/Buttons/BaseButton";
import BaseArrowBackIcon from "../../../core/Base/Arrows/BaseArrowBackIcon";

export default {
  name: "CourseInfo",
  components: {BaseArrowBackIcon, BaseButton},
  data() {
    return {
      form: {
        instructor: '',
        courseStatus: {
          online: false,
          attendence: false,
          mixed: false,
        },
        learningPath: '',
        learningPathValues: [],
        price: '',
      },
      searchFromList: '',
      coursesList: [
        {
          id: '1',
          title: 'الدورة 1'
        },
        {
          id: '2',
          title: 'الدورة 2'
        },
        {
          id: '3',
          title: 'الدورة 3'
        },
      ],
      categories: ['Syria', 'Lebanon', 'UAE']
    };
  },
  methods: {
    addCourseToLearningPath() {
      if (this.form.learningPath !== '') {
        this.form.learningPathValues.push(this.form.learningPath)
      }
    },
  },
  computed :{
    isView(){
      return this.$route.query.type == 'view';
    }
  }
}
</script>

<style scoped>

</style>
